<template>
    <div class="rounded-2xl bg-white">
        <div class="flex flex-wrap items-center content-between justify-between">
            <span class="font-semibold text-base"></span>
            <div class="flex">
                <div class="text-xs relative mr-4">
                    <select name="" class="block border border-gray-200 w-24 rounded-lg text-xs" @change="getDevice()"
                        v-model="tipe_perangkat" id="">
                        <option value="" selected>Filter</option>
                        <option value="level">Ketinggian Air</option>
                        <option value="WQ">Kualitas Air</option>
                        <option value="rain">Curah Hujan</option>
                        <option value="weather">Cuaca</option>
                    </select>
                </div>
                <div class="text-xs relative">
                    <div class="absolute top-2.5 left-2.5">
                        <unicon name="search" class="" width="14.34px" height="14.33px" fill="#A1A1AA"></unicon>

                    </div>

                    <input v-model="keyword" type="text"
                        :class="`block border border-gray-200 w-72 pr-2 pl-8 px-4 rounded-lg text-xs`" name="keyword"
                        ref="keyword" id="keyword" @keyup.enter="searchData()" placeholder="Pencarian" />
                </div>
            </div>

        </div>

        <div class="mt-4">
            <table class="w-full">
                <thead>
                    <tr class="border-b border-gray-200 text-gray-400 font-semibold">
                        <td class="py-4">Nama Perangkat</td>
                        <td>Kode Perangkat</td>
                        <td>Tipe Perangkat</td>
                        <td>Update Terakhir</td>
                        <td class="w-40">Aksi</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in data" :key="item.id"
                        class="border-b border-gray-200 font-semibold hover:bg-gray-100">
                        <td class="py-4">
                            <div class="flex items-center">
                                <div class="w-8 h-8 rounded-full mr-4">
                                    <img :src="item.url_img" class="w-8 h-8 rounded-full object-cover" alt="">
                                </div>
                                <span>{{ item.name }}</span>
                            </div>
                        </td>
                        <td>
                            <span>{{ item.code }}</span>
                        </td>
                        <td>
                            <span>{{ item.tipe_sensor }}</span>
                        </td>
                        <td>
                            <span v-html="item.date_parse"></span>
                        </td>
                        <td>
                            <button class="py-2 w-full rounded cursor-pointer bg-blue-500 hover:bg-blue-600 text-white"
                                @click="goDetail(item)">Monitor</button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="flex justify-end items-center mt-4">

                <div class="flex items-center ">
                    <div class="font-medium">
                        <span>{{ startNumber }}-{{ endNumber }} of {{ totalRows }}</span>
                    </div>
                    <div class="flex">
                        <unicon name="angle-left" class="cursor-pointer" :fill="currentPage > 1 ? '#00000' : '#A1A1AA'"
                            @click="previousPage()"></unicon>
                        <unicon name="angle-right" class="cursor-pointer"
                            :fill="totalRows == endNumber ? '#A1A1AA' : '#00000'" @click="nextPage()"></unicon>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
export default {
    data: () => ({
        data: [],
        totalRows: 100,
        perPage: 10,
        limit: 8,
        startNumber: 1,
        endNumber: 8,
        tipe_perangkat: '',
        currentPage: 1,
        disabled: false,
        modal_dialog: false,
        keyword: '',
        foto: '',
        delete_id: '',
        modal_edit: false,
        default_url: process.env.VUE_APP_IMAGES
    }),
    mixins: [globalMixin],
    methods: {
        ...mapActions({
            setCurrentDevice: 'device/setCurrentDevice',
        }),
        getData() {
            this.getDevice(); // Call getDevice or your desired logic here
        },
        getDevice() {
            let customer_id = this.customer ? this.customer.id : ''
            this.axios.get(`v1/device?page=${this.currentPage}&limit=${this.limit}&keyword=${this.keyword}&status=1&customer_id=${customer_id}&tipe_perangkat=${this.tipe_perangkat}`, this.config)
                .then((ress) => {
                    this.data = ress.data.rows;
                    this.totalRows = ress.data.count;
                    if (this.totalRows <= this.limit) {
                        this.endNumber = this.totalRows;
                    } else if (this.currentPage > 1) {
                        let page = this.currentPage - 1;
                        this.startNumber = page * this.limit + 1;
                        this.endNumber = this.currentPage * this.limit;
                        if (this.totalRows < this.endNumber) {
                            this.endNumber = this.totalRows;
                        }
                    } else {
                        this.startNumber = 1;
                        this.endNumber = this.limit;
                    }
                    this.startNumber = this.totalRows < 1 ? 0 : (this.currentPage - 1) * this.limit + 1;
                })
                .catch((err) => {
                    this.errorResponse(err);
                });
        },

        goDetail(item) {
            this.setCurrentDevice(item);
            this.close();
        },

        close() {
            this.$emit('close')
        }


    },
    computed: {
        ...mapGetters({
            token: 'auth/token',
            customer: 'device/customer'
        }),

    },
    created() {
        this.getDevice()
    },
    components: {

    }
}
</script>