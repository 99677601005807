export default {
    data: () => ({
        optionsBatre:
            {
            series: [{
                name: 'Batre',
                data: []
            }],
            animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                    speed: 1000
                }
            },
            labels:[],
            chart: {
                type: 'line',
                stacked: false,
                // height: 200,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    autoSelected: 'zoom'
                }
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
            },
            title: {
                
                align: 'left'
            },
            fill: {
            },
            
            yaxis: {
               
                labels:{
                                   formatter:function(val) {
                            return parseFloat(val).toFixed(2)
                        }
                },
                tickAmount: 10,
                min: 3,
                max: 4.50,
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeUTC: false
                }
            },
            tooltip: {
                x: {
                    format: 'y-MM-dd HH:mm:ss',
                }
            },
            
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'round',
                width: 2,
                dashArray: 0,      
            }
        },
    }),
}