<template>
    <div class="p-8 text-xs">
        <button @click="$router.push('/monitor')" class="flex items-center text-blue-500">
            <!-- Font Awesome for "chevron-left" -->
            <i class="fas fa-chevron-left w-6 h-6 mb-2"></i>
            <span class="ml-2 mb-5">Kembali</span>
        </button>
        <div class="">
            <div class="">
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 md:col-span-4  rounded-2xl bg-white p-4 flex items-center">
                        <div class="rounded-full bg-blue-50 p-3 flex flex-wrap justify-center items-center mr-4 border" >
                            <unicon name="camera" fill="blue"></unicon>
                        </div>
                        <div>
                            <textLevel :item="currentDevice" class="text-lg" />
                            <p class="text-gray-500">Timelapse</p>
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-4  rounded-2xl bg-white p-4 flex items-center">
                        <div class="flex flex-wrap justify-center items-center rounded-full bg-green-50 px-4 py-5 mr-4" v-if="currentDevice.persentase_battery >= 80" >
                            <img src="/img/100.svg" alt=""> 
                        </div>
                        <div class="flex flex-wrap justify-center items-center rounded-full bg-green-50 px-4 py-5 mr-4" v-else-if="currentDevice.persentase_battery < 80 && currentDevice.persentase_battery > 50">
                                <img src="/img/75.svg" alt=""> 
                        </div>
                        <div class="flex flex-wrap justify-center items-center rounded-full bg-yellow-50 px-4 py-5 mr-4" v-else-if="currentDevice.persentase_battery <= 50 && currentDevice.persentase_battery >= 40">
                                <img src="/img/50.svg" alt=""> 

                        </div>
                        <div class="flex flex-wrap justify-center items-center rounded-full bg-red-50 px-4 py-5 mr-4" v-else-if="currentDevice.persentase_battery < 40 && currentDevice.persentase_battery > 0">
                            <img src="/img/25.svg" alt=""> 

                        </div>
                        <div class="flex flex-wrap justify-center items-center rounded-full bg-red-50 px-4 py-5 mr-4" v-else-if="currentDevice.persentase_battery <= 0 " >
                            <img src="/img/0.svg" alt=""> 

                        </div>
                        <div>
                            <p class="text-lg font-bold">{{currentDevice.persentase_battery}}%</p>
                            <p class="text-gray-500">Baterai</p>
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-4  rounded-2xl bg-white p-4 flex items-center">
                        <div class="p-4 rounded-full bg-blue-50 flex flex-wrap justify-center mr-4">
                            <unicon width="20px" height="20px" name="brightness" fill="#3B82F6" ></unicon>
                        </div>
                        <div>
                            <p class="text-lg font-bold">{{currentDevice.VSOL}} V</p>
                            <p class="text-gray-500">Voltase Solar Panel</p>
                        </div>
                    </div>
                </div>
                <div class="mt-4 mb-2">
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12  md:col-span-8 rounded bg-white z-30 md:h-full">
                            <map-component class="rounded-2xl" :device="device" v-if="device.length"/>
                        </div>
                        <div class="col-span-12 md:col-span-4 rounded-2xl p-6 bg-white">
                            <div class="">
                                <span class="text-lg font-bold">{{currentDevice.name}}</span>
                                <br>
                                <span class="text-gray-500">{{currentDevice.code}}</span>
                            </div>
                            <div @click="dialog = true" class="mt-4 cursor-pointer rounded-lg text-sm font-semibold text-center py-2 text-white bg-blue-500 hover:bg-blue-600">
                                Ganti Perangkat
                            </div>

                            <hr class="mt-4">

                            <div class="flex items-center text-sm mt-4">
                                <unicon width="20px" height="20px" name="location-point" fill="#9CA3AF" class="mr-2"></unicon>
                                <a class="text-gray-500" :href="`https://www.google.com/maps/place/${currentDevice.latitude} ${currentDevice.longitude}`" target="_blank" rel="noopener noreferrer">
                                {{currentDevice.latitude}} {{currentDevice.longitude}}
                                </a>
                            </div>
                            <hr class="mt-4">

                            <div class="flex items-center text-sm mt-4 text-gray-500">
                                <unicon width="20px" height="20px" name="calendar-alt" fill="#9CA3AF" class="mr-2"></unicon>
                                {{parseDate(currentDevice.last_update,'YYYY-MM-DD HH:mm:ss')}}
                            </div>
                            <hr class="mt-4">
                            <div class="flex items-center text-sm mt-4 text-gray-500">
                                <unicon width="20px" height="20px" name="sim-card" fill="#9CA3AF" class="mr-2"></unicon>
                                {{currentDevice.gsm?currentDevice.gsm.name : ''}}
                            </div>
                            <hr class="mt-4">
                                <div class="flex items-center text-sm mt-4 text-gray-500">
                                <unicon width="20px" height="20px" name="signal" fill="#9CA3AF" class="mr-2"></unicon>
                                {{parseFloat(currentDevice.CSQ).toFixed(0)}} Sig[-]
                                
                            </div>
                            <hr class="mt-4">
                                <div class="flex items-center text-sm mt-4 text-gray-500">
                                <unicon width="20px" height="20px" name="circuit" fill="#9CA3AF" class="mr-2"></unicon>
                                {{currentDevice.tipe_sensor}}
                            </div>

                        </div>

                        <div class="col-span-12 md:col-span-12 lg:col-span-12 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <div class="flex justify-between w-full">
                                    <div class="">
                                        <p class="font-bold text-base">Timelapse</p> 
                                        <!-- <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.real_ketinggian * 100).toFixed(2)}} m maximum eave height pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span> -->
                                    </div>
                                    <div class="">
                                        <date-range-picker
                                            v-model="dateRange"
                                            :locale-data="locale"
                                            :time-picker="true"
                                            :time-picker24-hour="true"
                                            :time-picker-seconds="true"
                                            opens="left"
                                            >
                                            <template v-slot:input="picker" style="min-width: 350px;">
                                                {{ formatDateTime(picker.startDate) }} - {{ formatDateTime(picker.endDate) }}
                                            </template>
                                        </date-range-picker>
                                        <!-- <p>Selected Date Range: {{ dateRange.startDate.format('dd-mm-yyyy HH:mm:ss') }} - {{ dateRange.endDate.format('dd-mm-yyyy HH:mm:ss') }}</p> -->

                                    </div>
                                </div>
                            </div>
                            <div class="pt-4 mx-8 overflow-x-auto flex gap-x-8">
                                <div v-if="list_date_dummy" v-for="(datetime, index) in list_date_dummy" :key="index" class="w-40 flex-shrink-0 flex flex-col justify-between">
                                    <img alt="Picture" class="h-40 hover:opacity-50 cursor-zoom-in" :src="require('../../assets/CatchmenArea.png')" @click="open_dialog_timelapse(formatDateTime(datetime))">
                                    <p class="text-center">{{ formatDateTime(datetime) }}</p>
                                </div>
                                <div v-if="!list_date_dummy" class="w-full py-12">
                                    <h1 class="text-4xl text-center text-gray-500">Data tidak tersedia !</h1>
                                </div>
                            </div>

                        </div>                        
                    </div>
                </div>

                <div id="form_lamaran" v-if="dialog" class="absolute"  style="z-index:110000 !important">
                <div class="fixed inset-0 z-50 opacity-50 bg-black"></div>
                    <div class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50">
                        <div class="bg-white w-full md:w-3/4">
                            <div class="bg-gray-100 p-4 rounded rounded-b-none flex justify-between items-center">
                                <p class="text-lg font-medium">DATA PERANGAKAT</p>
                                <i class="far fa-times text-lg cursor-pointer" @click="closeDialog"></i>
                            </div>
                            <div class="bg-white w-full rounded rounded-t-none p-4 overflow-auto max-h-screen">
                                <div style="w-full">
                                    <device-component class="w-full" v-on:close="closeDialog"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div id="form_lamaran" v-if="dialog_danger" class="absolute" >
                <div class="fixed inset-0 z-50 opacity-50 bg-black"></div>
                    <div class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50">
                        <div class="relative mx-auto w-auto max-w-full">
                            <div class="bg-white w-full rounded p-4 overflow-auto max-h-screen">
                                <div >
                                    <div class="flex justify-center text-center">
                                        <div class="text-center">
                                            <div class="font-bold"> 
                                                <p>{{currentDevice.name}}</p>
                                                <span class="text-red-500 text-sm" v-if="currentDevice.real_ketinggian >= currentDevice.siaga_1"> {{parseFloat(currentDevice.real_ketinggian).toFixed(2)}} cm 
                                                <br>
                                                Siaga 1</span>
                                                <span class="text-yellow-500 text-sm" v-else-if="currentDevice.real_ketinggian <= currentDevice.siaga_1 && currentDevice.real_ketinggian >= currentDevice.siaga_2">
                                                    {{parseFloat(currentDevice.real_ketinggian * 100).toFixed(2)}} cm 
                                                <br>
                                                    Siaga 2</span>
    
                                                    
                                            </div>
                                            <div class="flex justify-center">
                                                <img src="/siren.svg" class="h-16" alt="">
                                            </div>
                                            Terakhir Diperbaharui
                                            <br>
                                            {{parseDate(currentDevice.last_update)}}

                                            <div class="modal-close btn border mt-2 border-gray-300 p-1 px-4 font-semibold hover:bg-gray-100
                                            cursor-pointer text-gray-500 ml-auto rounded" id="closemenu" @click="stop()">
                                            Tutup</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <audio id="backMusic">
                <source :src="require('../../assets/emergency.mp3')" type="audio/mpeg">
            </audio>
        <!-- dialog timelapse -->
        <div id="form_lamaran" v-if="dialog_timelapse" class="absolute"  style="z-index:110000 !important">
        <div class="fixed inset-0 z-50 opacity-50 bg-black"></div>
            <div class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50">
                <div class="bg-white w-full md:w-3/4">
                    <div class="bg-gray-100 p-4 rounded rounded-b-none flex justify-between items-center">
                        <p class="text-lg font-medium">Captured photo</p>
                        <i class="far fa-times text-lg cursor-pointer" @click="closeDialogTimelapse"></i>
                    </div>
                    <div class="bg-black mx-40 my-8 rounded rounded-t-none overflow-auto max-h-screen">                        
                        <div class="w-full" v-on:close="closeDialogTimelapse">
                            <div class="catchmen p-4" style="height: 500px;">
                                <div class="flex justify-between text-white">
                                    <p class="">Create Date: {{choosed_time}}</p>
                                    <p class="">VSOL: 10</p>
                                </div>
                                <div class="flex justify-between text-white">
                                    <p class="">VBAT: 100%</p>
                                    <p class="">CSQ: 12331</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            
    </div>
</template>
<style scoped>
.catchmen {
  background-image: url('../../assets/CatchmenArea.png');
  background-repeat: no-repeat;
  background-position: center;
}
</style>
<script>
import { Carousel, Slide } from 'vue-carousel';
import { mapActions, mapGetters } from 'vuex';
import deviceComponent from '../../components/global/device.vue'
import mapComponent from '../../components/singleMap.vue'
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import moment from 'moment'
import optionBatre from './optionBatre'
import optionSignal from './optionSignal'
import optionVsol from './optionVsol'
import globalMixin from '../../mixin/global'
import textLevel from '../../components/dashboard/textLevel.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { root } from 'postcss';
export default {
    data () {
    return {
        hours: 0,
        minutes: 0,
        seconds: 0,
        dialog:false,
        loadingChart:false,
        update:'',
        mute: true,
        audio2: null,
        dialog_danger:false,
        dialog_timelapse:false,
        first_danger:true,
        list_date_dummy: false,
        choosed_time:'',
        

        // datepicker
        dateRange: {
            startDate: moment().startOf('month'),
            endDate: moment().endOf('month')
        },
        locale: {
            direction: 'ltr',
            format: 'dd-mm-yyyy HH:mm:ss',
            separator: ' - ',
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            weekLabel: 'W',
            customRangeLabel: 'Custom Range',
            daysOfWeek: moment.weekdaysMin(),
            monthNames: moment.monthsShort(),
            firstDay: 1,
            autoApply: true,
        }
      }
    },
    components: {
        Carousel,
        Slide,
        deviceComponent,mapComponent,textLevel,
        DateRangePicker,
    },
    mixins:[globalMixin, optionBatre,optionSignal,optionVsol],
    watch: {
        dateRange: {
        handler(val) {
            if (val.startDate && val.endDate) {
                let start = new Date(val.startDate);
                let end = new Date(val.endDate);
                let dateTimes = [];
                
                while (start <= end) {
                    dateTimes.push(new Date(start));
                    start.setMinutes(start.getMinutes() + 30);
                }
                
                this.list_date_dummy = dateTimes;
            }
        },
        deep: true
        }
    },
    methods: {    
        ...mapActions({
           setCurrentDevice:'device/setCurrentDevice',
        }),
        formatDateTime(datetime) {
            let day = datetime.getDate().toString().padStart(2, '0');
            let month = (datetime.getMonth() + 1).toString().padStart(2, '0');
            let year = datetime.getFullYear();
            let hours = datetime.getHours().toString().padStart(2, '0');
            let minutes = datetime.getMinutes().toString().padStart(2, '0');
            let seconds = datetime.getSeconds().toString().padStart(2, '0');
            
            return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
        },
        setTime () {
            setInterval(() => {
                const date = new Date()
                this.hours = date.getHours()
                this.minutes = this.checkSingleDigit(date.getMinutes())
                this.seconds = this.checkSingleDigit(date.getSeconds())
            }, 1000)
        },
        closeDialog() {
            this.dialog = false
        },
        open_dialog_timelapse(datetime) {
            this.choosed_time = datetime;
            this.dialog_timelapse = true;
        },
        closeDialogTimelapse() {
            this.dialog_timelapse = false;
        },
        checkSingleDigit (digit) {
            return ('0' + digit).slice(-2)
        },
    
        async getData() {
            await this.axios.get('v2/device/detail/data/' + this.currentDevice.id,this.config)
            .then((ress) => {
                this.dataChart = ress.data
                  
            })
            .catch((err) => {
                this.errorResponse(err)
            })
        },
        play(){
            
            this.audio2.play();
            this.audio2.loop = true;
            this.mute = false;
           
        },
        stop() {
            this.dialog_danger = false
            this.audio2.pause();
            this.mute = true;
        },
    
    }, 
    computed:{
        ...mapGetters({
            device:'device/showDevice',
            currentDevice:'device/currentDevice'
        })
    },
    async created() {
        if (!this.currentDevice) {
            this.$router.push('/monitor')
        }
    },
    mounted () {
        this.setTime()

        this.audio2 = document.getElementById('backMusic');

        // default hari ini
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let now = new Date();
        let dateTimes = [];
        while (today <= now) {
            dateTimes.push(new Date(today));
            today.setMinutes(today.getMinutes() + 30);
        }
        this.list_date_dummy = dateTimes;

    },
    beforeDestroy() {
        this.sockets.unsubscribe('updatedatachart');

        clearInterval(this.setTime)
        this.audio2.pause();
        this.mute = true;
    }
 
}
</script>

