export default {
    data: () => ({
        optionsVsol:
            {
            series: [{
                name: 'Voltase Solar Panel V',
                data: []
            }],
            animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                    speed: 1000
                }
            },
            labels:[],
            chart: {
                type: 'area',
                stacked: false,
                height: 200,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    autoSelected: 'zoom'
                }
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
            },
            title: {
                
                align: 'left'
            },
            fill: {
                // type: 'gradient',
                // gradient: {
                //     shadeIntensity: 1,
                //     inverseColors: false,
                //     opacityFrom: 0.9,
                //     opacityTo: 0,
                //     stops: [0, 90, 100]
                // },
                colors:['#3B82F6']

            },
            yaxis: {
               
                labels:{
                
                },
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeUTC: false
                }
            },
            tooltip: {
                x: {
                    format: 'y-MM-dd HH:mm:ss',
                }
            },
            
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'round',
                width: 2,
                dashArray: 0,      
            }
        },
    }),
}