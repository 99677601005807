export default {
    data: () => ({
        optionsElevasi:
            {
            zoom: {
                enabled: false
            },
            series: [{
                name: 'Elevasi Muka Air [m]',
                data: []
            }],
            animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                    speed: 1000
                }
            },
            labels:[],
            chart: {
                type: 'area',
                stacked: false,
                height: 200,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    autoSelected: 'zoom'
                }
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
            },
            title: {
                
                align: 'left'
            },
            fill: {
                // type: 'gradient',
                // gradient: {
                //     shadeIntensity: 1,
                //     inverseColors: false,
                //     opacityFrom: 0.9,
                //     opacityTo: 0,
                //     stops: [0, 90, 100]
                // },
                colors:['#3B82F6']

            },
            yaxis: {
                min: 20.00,
                max: 25.00,
                tickAmount: 5,
                labels:{
                
                },
              
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeUTC: false
                }
            },
            tooltip: {
                marker: {
                    show: false,
                },
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    return `<div class="p-4">
                    <span class="text-green-400 font-bold"> h : ${w.config.series[seriesIndex].data[dataPointIndex].h ? w.config.series[seriesIndex].data[dataPointIndex].h : ''} cm </span>
                      
                      <br>
                      <span class="mt-2 text-blue-400 font-bold">Elevasi Muka Air : ${series[seriesIndex][dataPointIndex]} m</span>
                    
                      <br>
                      <span class="mt-2 text-yellow-400 font-bold"> Q :${w.config.series[seriesIndex].data[dataPointIndex].debit ? w.config.series[seriesIndex].data[dataPointIndex].debit : ''} m3/dt </span>
                      </div>`
                },
                x: {
                    format: 'y-MM-dd HH:mm:ss',
                }
            
            },
            
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'round',
                width: 2,
                dashArray: 0,      
            }
        },
    }),
}