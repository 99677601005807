<template>
     <div class="relative w-full flex flex-wrap content-center justify-center">
        <div class="">
            <div class="w-12 bg-blue-800" :style="`height:14.2px;`"></div>
            <div class="w-12 bg-blue-700" :style="`height:14.2px;`"></div>
            <div class="w-12 bg-blue-600" :style="`height:14.2px;`"></div>
            <div class="w-12 bg-green-800" :style="`height:14.2px;`"></div>
            <div class="w-12 bg-green-700" :style="`height:14.2px;`"></div>
            <div class="w-12 bg-green-600" :style="`height:14.2px;`"></div>
            <div class="w-12 bg-green-400" :style="`height:14.2px;`"></div>
            <div class="w-12 bg-green-300" :style="`height:14.2px;`"></div>
            <div class="w-12 bg-green-100" :style="`height:14.2px;`"></div>
            <div class="w-12 bg-yellow-300" :style="`height:14.2px;`"></div>
            <div class="w-12 bg-yellow-400" :style="`height:14.2px;`"></div>
            <div class="w-12 bg-yellow-500" :style="`height:14.2px;`"></div>
            <div class="w-12 bg-red-500" :style="`height:14.2px;`"></div>
            <div class="w-12 bg-red-600" :style="`height:14.2px;`"></div>



        </div>
        <div class="absolute bottom-0 w-full text-left text-gray-700 font-bold"  :style="`height:23px;`" v-if="currentDevice.pH >= 1 && currentDevice.pH < 2" >
            pH {{parseFloat(currentDevice.pH).toFixed(2)}} 
            <div style="height:1px" class="bg-black w-2/4 bg-red-600"></div>
        </div>
        <div class="absolute bottom-0 w-full text-left text-gray-700 font-bold"  :style="`height:36px;`" v-else-if="currentDevice.pH >= 2 && currentDevice.pH < 3">
            pH {{parseFloat(currentDevice.pH).toFixed(2)}} 
            <div style="height:1px" class="bg-black w-2/4 bg-red-500"></div>
        </div>
        <div class="absolute bottom-0 w-full text-left text-gray-700 font-bold"  :style="`height:50px;`" v-else-if="currentDevice.pH >= 3 && currentDevice.pH < 4">
            pH {{parseFloat(currentDevice.pH).toFixed(2)}} 
            <div style="height:1px" class="bg-black w-2/4 bg-yellow-500"></div>
        </div>
        <div class="absolute bottom-0 w-full text-left text-gray-700 font-bold"  :style="`height:66px;`" v-else-if="currentDevice.pH >= 4 && currentDevice.pH < 5">
            pH {{parseFloat(currentDevice.pH).toFixed(2)}} 
            <div style="height:1px" class="bg-black w-2/4 bg-yellow-400"></div>
        </div>
        <div class="absolute bottom-0 w-full text-left text-gray-700 font-bold"  :style="`height:80px;`" v-else-if="currentDevice.pH >= 5 && currentDevice.pH < 6">
            pH {{parseFloat(currentDevice.pH).toFixed(2)}} 
            <div style="height:1px" class="bg-black w-2/4 bg-yellow-300"></div>
        </div>
        <div class="absolute bottom-0 w-full text-left text-gray-700 font-bold"  :style="`height:95px;`"  v-else-if="currentDevice.pH >= 6 && currentDevice.pH < 7">
            pH {{parseFloat(currentDevice.pH).toFixed(2)}} 
            <div style="height:1px" class="bg-black w-2/4 bg-green-100"></div>
        </div>
        <div class="absolute bottom-0 w-full text-left text-gray-700 font-bold"  :style="`height:108px;`"  v-else-if="currentDevice.pH >= 7 && currentDevice.pH < 8">
            pH {{parseFloat(currentDevice.pH).toFixed(2)}} 
            <div style="height:1px" class="bg-black w-2/4 bg-green-300"></div>
        </div>
        <div class="absolute bottom-0 w-full text-left text-gray-700 font-bold"  :style="`height:123px;`"  v-else-if="currentDevice.pH >= 8 && currentDevice.pH < 9">
            pH {{parseFloat(currentDevice.pH).toFixed(2)}} 
            <div style="height:1px" class="bg-black w-2/4 bg-green-400"></div>
        </div>
            <div class="absolute bottom-0 w-full text-left text-gray-700 font-bold"  :style="`height:136px;`"  v-else-if="currentDevice.pH >= 9 && currentDevice.pH < 10">
            pH {{parseFloat(currentDevice.pH).toFixed(2)}} 
            <div style="height:1px" class="bg-black w-2/4 bg-green-600"></div>
        </div>
        <div class="absolute bottom-0 w-full text-left text-gray-700 font-bold"  :style="`height:152px;`"  v-else-if="currentDevice.pH >= 10 && currentDevice.pH < 11">
            pH {{parseFloat(currentDevice.pH).toFixed(2)}} 
            <div style="height:1px" class="bg-black w-2/4 bg-green-700"></div>
        </div>
        <div class="absolute bottom-0 w-full text-left text-gray-700 font-bold"  :style="`height:166px;`"  v-else-if="currentDevice.pH >= 11 && currentDevice.pH < 12">
            pH {{parseFloat(currentDevice.pH).toFixed(2)}} 
            <div style="height:1px" class="bg-black w-2/4 bg-green-800"></div>
        </div>
            <div class="absolute bottom-0 w-full text-left text-gray-700 font-bold"  :style="`height:179px;`"  v-else-if="currentDevice.pH >= 12 && currentDevice.pH < 13">
            pH {{parseFloat(currentDevice.pH).toFixed(2)}} 
            <div style="height:1px" class="bg-black w-2/4 bg-blue-600"></div>
        </div>
        <div class="absolute bottom-0 w-full text-left text-gray-700 font-bold"  :style="`height:194px;`"  v-else-if="currentDevice.pH >= 13 && currentDevice.pH < 14">
            pH {{parseFloat(currentDevice.pH).toFixed(2)}} 
            <div style="height:1px" class="bg-black w-2/4 bg-blue-700"></div>
        </div>
        <div class="absolute bottom-0 w-full text-left text-gray-700 font-bold "  :style="`height:208px;`"   v-else-if="currentDevice.pH >= 14">
            pH {{parseFloat(currentDevice.pH).toFixed(2)}} 
            <div style="height:1px" class="bg-black w-2/4 bg-blue-800"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['currentDevice']
}
</script>