<template>

  <l-map
      v-if="showMap"
      :zoom="zoom"
      :center="position"
      :options="mapOptions"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      ref="map"
    
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
        <l-control
        :position="'bottomleft'"
        v-if="!showLegend"
      >
        <div class="bg-white rounded p-1 flex items-center cursor-pointer" @click="showLegend = true">
          <unicon name="layer-group" class="" width="22px" fill="#A1A1AA" ></unicon>
        </div>

        <div class="mt-2 bg-white rounded ">
          <div class="px-1 text-center flex justify-center items-center cursor-pointer text-lg" @click="zoom++">
          +
          </div>
           <div class="px-1 text-center flex justify-center items-center cursor-pointer text-lg" @click="zoom--">
          -
          </div>
        </div>
      </l-control>
     <l-control
        :position="'bottomleft'"
        v-else
      >
        <div class="bg-white rounded-xl w-44 border-gray-300 relative" >
          <div class="absolute top-1 right-1 text-xs cursor-pointer" @click="showLegend = false">x</div>
          <div class="px-4 py-2 font-bold text-xs border-b border-gray-200">
            Map Legend
          </div>

          <div class="px-4 py-2 text-xs border-b border-gray-200 max-h-40 overflow-auto ">

            <div class="flex items-center mt-2">
              <unicon name="water" class="mr-2" width="20px"></unicon>
              <span>Kualitas Air</span>
            </div>

             <div class="flex items-center mt-2">
              <unicon name="wind" class="mr-2" width="20px"></unicon>
              <span>Cuaca</span>
            </div>

            <div class="flex items-center mt-2">
              <unicon name="cloud-showers-heavy" class="mr-2" width="20px"></unicon>
              <span>Curah Hujan</span>
            </div>

            <div class="flex items-center mt-2">
              <img src="/img/level_legend.svg" class="mr-2" style="width:20px" alt="">
              <span>Ketinggian Air</span>
            </div>

            <div class="flex items-center mt-2">
              <unicon name="camera" class="mr-2" width="20px"></unicon>
              <span>Timlapse</span>
            </div>
            
          </div>
          <div class="px-4 py-2 text-xs border-b border-gray-200 max-h-40 overflow-auto ">

             <div class="flex items-center mt-2">
              <img src="/img/map_siaga1.svg" class="mr-2" style="width:20px" alt="">
              <span>Siaga 1</span>

            </div>

            <div class="flex items-center mt-2">
              <img src="/img/map_siaga2.svg" class="mr-2" style="width:20px" alt="">
              <span>Siaga 2</span>

            </div>
             <div class="flex items-center mt-2">
              <img src="/img/map_siaga3.svg" class="mr-2" style="width:20px" alt="">
              <span>Siaga 3</span>

            </div>
            <div class="flex items-center mt-2">
              <img src="/img/map_siaga4.svg" class="mr-2" style="width:20px" alt="">
              <span>Siaga 4</span>

            </div>


            
          </div>
        </div>
      </l-control>
      <l-marker :lat-lng="position">
           <l-icon
          :icon-url="image_url"
          >
          </l-icon>
          <l-popup>
            <div>
                {{device.name}}
                
            </div>
          </l-popup>
      </l-marker>
    </l-map>

</template>

<script>
import { latLng } from 'leaflet';
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
    LControl,
  LFeatureGroup,
  LIcon
} from 'vue2-leaflet';
import { mapGetters } from 'vuex';

export default {
  name: 'Example',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon,
    LFeatureGroup,
    LControl

  },
  data() {
    return {
      zoom: 10,
      load:false,
      maker:[],
      position:'',
      showLegend:false,
      center: latLng(-6.057941, 106.465759),
      url: 'https://tile.jawg.io/jawg-sunny/{z}/{x}/{y}{r}.png?access-token=7glTAS2WFrjG3dk60c4oz44y9FWvwgoAiWjViEhr1WAKxJxQfjETV0d6QIqgNOkp',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: latLng(47.41322, -1.219482),
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,zoomControl: false
      },
      showMap: false,
      showLegend:false,
      image_url:''
      
    };
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert('Click!');
    },
  },
  computed:{
      ...mapGetters({
          device:'device/currentDevice'
      }),
      lokasi: function () {
      // `this` points to the vm instance
          this.position = latLng(this.device.latitude,this.device.longitude)

         return latLng(this.device.latitude,this.device.longitude)
      }
  },
  watch: {
    // whenever question changes, this function will run
    device: function (newQuestion, oldQuestion) {
      this.position = latLng(newQuestion.latitude,newQuestion.longitude)
    }
  },
  async created(){
      this.position = this.lokasi
       
                if(this.device.type == 'WQ'){
                    this.image_url = '/img/map_wq.svg'
                } else if(this.device.type == 'level'){
                    if(this.device.siaga_level == 1) {
                    this.image_url = '/img/map_siaga1.svg'
                    }
                    if(this.device.siaga_level == 2) {
                    this.image_url = '/img/map_siaga2.svg'
                    }
                    if(this.device.siaga_level == 3) {
                    this.image_url = '/img/map_siaga3.svg'
                    }
                    if(this.device.siaga_level == 4) {
                    this.image_url = '/img/map_siaga4.svg'
                    }
                } else if(this.device.type == 'rain'){
                    this.image_url = '/img/rain_map.svg'
                }else if(this.device.type == 'weather'){
                    this.image_url = '/img/weather_map.svg'
                }else if(this.device.type == 'camera'){
                    this.image_url = '/img/camera.svg'
                }
      this.showMap = true
  }
};
</script>

<style>

</style>
