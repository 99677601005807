<template>
    <div class="p-8 text-xs">
        <button @click="$router.push('/monitor')" class="flex items-center text-blue-500">
            <!-- Font Awesome for "chevron-left" -->
            <i class="fas fa-chevron-left w-6 h-6 mb-2"></i>
            <span class="ml-2 mb-5">Kembali</span>
        </button>
        <div class="">
            <div class="">
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 md:col-span-4 rounded-2xl bg-white p-4 flex items-center">
                            <div class="p-4 rounded-full bg-blue-50 flex flex-wrap justify-center mr-4">
                                <unicon width="20px" height="20px" name="water" fill="#3B82F6" ></unicon>

                            </div>
                            <div>
                                <textPh :item="currentDevice" class="text-lg" />
                                <p class="text-gray-500">PH air</p>
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-4 rounded-2xl bg-white p-4 flex items-center">
                            <div class="flex flex-wrap justify-center items-center rounded-full bg-green-50 px-4 py-5 mr-4" v-if="currentDevice.persentase_battery >= 80" >
                                <img src="/img/100.svg" alt=""> 
                            </div>
                            <div class="flex flex-wrap justify-center items-center rounded-full bg-green-50 px-4 py-5 mr-4" v-else-if="currentDevice.persentase_battery < 80 && currentDevice.persentase_battery > 50">
                                 <img src="/img/75.svg" alt=""> 
                            </div>
                            <div class="flex flex-wrap justify-center items-center rounded-full bg-yellow-50 px-4 py-5 mr-4" v-else-if="currentDevice.persentase_battery <= 50 && currentDevice.persentase_battery >= 40">
                                 <img src="/img/50.svg" alt=""> 

                            </div>
                            <div class="flex flex-wrap justify-center items-center rounded-full bg-red-50 px-4 py-5 mr-4" v-else-if="currentDevice.persentase_battery < 40 && currentDevice.persentase_battery > 0">
                                <img src="/img/25.svg" alt=""> 

                            </div>
                            <div class="flex flex-wrap justify-center items-center rounded-full bg-red-50 px-4 py-5 mr-4" v-else-if="currentDevice.persentase_battery <= 0 " >
                                <img src="/img/0.svg" alt=""> 

                            </div>
                            <div>
                                <p class="text-lg font-bold">{{currentDevice.persentase_battery}}%</p>
                                <p class="text-gray-500">Baterai</p>
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-4 rounded-2xl bg-white p-4 flex items-center">
                                <div class="p-4 rounded-full bg-blue-50 flex flex-wrap justify-center mr-4">
                                <unicon width="20px" height="20px" name="brightness" fill="#3B82F6" ></unicon>

                                </div>
                                <div>
                                    <p class="text-lg font-bold">{{currentDevice.VSOL}} V</p>
                                    <p class="text-gray-500">Voltase Solar Panel</p>
                                </div>
                        </div>
                    </div>
                    <div class="mt-4 mb-2">
                       <div class="grid grid-cols-12 gap-4">
                              <div class="col-span-12  md:col-span-8 rounded-2xl rounded bg-white z-30 md:h-full">
                                <map-component class="rounded-2xl" :device="device" v-if="device.length"/>
                            </div>
                            <div class="col-span-12 md:col-span-4 rounded-2xl p-6 bg-white">
                                <div class="">
                                    <span class="text-lg font-bold">{{currentDevice.name}}</span>
                                    <br>
                                    <span class="text-gray-500">{{currentDevice.code}}</span>
                                </div>
                                <div @click="dialog = true" class="mt-4 cursor-pointer rounded-lg text-sm font-semibold text-center py-2 text-white bg-blue-500">
                                    Ganti Perangkat
                                </div>

                                <hr class="mt-4">

                                <div class="flex items-center text-sm mt-4">
                                    <unicon width="20px" height="20px" name="location-point" fill="#9CA3AF" class="mr-2"></unicon>
                                    <a class="text-gray-500" :href="`https://www.google.com/maps/place/${currentDevice.latitude} ${currentDevice.longitude}`" target="_blank" rel="noopener noreferrer">
                                    {{currentDevice.latitude}} {{currentDevice.longitude}}
                                    </a>
                                </div>
                                <hr class="mt-4">

                                <div class="flex items-center text-sm mt-4 text-gray-500">
                                    <unicon width="20px" height="20px" name="calendar-alt" fill="#9CA3AF" class="mr-2"></unicon>
                                    {{parseDate(currentDevice.last_update,'YYYY-MM-DD HH:mm:ss')}}
                                </div>
                                <hr class="mt-4">
                                <div class="flex items-center text-sm mt-4 text-gray-500">
                                    <unicon width="20px" height="20px" name="sim-card" fill="#9CA3AF" class="mr-2"></unicon>
                                   {{currentDevice.gsm?currentDevice.gsm.name : ''}}
                                </div>
                                <hr class="mt-4">
                                  <div class="flex items-center text-sm mt-4 text-gray-500">
                                    <unicon width="20px" height="20px" name="signal" fill="#9CA3AF" class="mr-2"></unicon>
                                   {{parseFloat(currentDevice.CSQ).toFixed(0)}} Sig[-]
                                  
                                </div>
                                <hr class="mt-4">
                                  <div class="flex items-center text-sm mt-4 text-gray-500">
                                    <unicon width="20px" height="20px" name="circuit" fill="#9CA3AF" class="mr-2"></unicon>
                                    {{currentDevice.tipe_sensor}}
                                </div>

                            </div>
                            
                       
                             <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">PH Air</p> 
                                    <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.pH).toFixed(2)}} ph air pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                    <div id="ph"></div>
                                </div>
                            </div>
                            
                          
                            <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Conductivity</p> 
                                    <span class="text-gray-500 text-xs">{{currentDevice.conductivity}} ms/cm conductifity pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                    <div id="conductivity"></div>
                                </div>
                            </div>
                            
                            <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Temperature</p> 
                                    <span class="text-gray-500 text-xs">{{currentDevice.temperature}} deg C temperature pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                      <div id="temperature"></div>
                                </div>
                            </div>
                            <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Oksigen Terlarut</p> 
                                    <span class="text-gray-500 text-xs">{{currentDevice.do}} mg/L oksigen terlarut pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                      <div id="do"></div>
                                </div>
                            </div>
                         
                             <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Kekeruhan air</p> 
                                    <span class="text-gray-500 text-xs">{{currentDevice.turbidity}} NTU Kekeruhan air pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                      <div id="turbidity"></div>
                                </div>
                            </div>
                             <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Amonium</p> 
                                    <span class="text-gray-500 text-xs">{{currentDevice.amonium}} Amonium pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                      <div id="amonium"></div>
                                </div>
                            </div>
                            <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Kepadatan Air</p> 
                                    <span class="text-gray-500 text-xs">{{currentDevice.density}} m3/kg Kepadatan Air pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                      <div id="density"></div>
                                </div>
                            </div>

                             <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Kadar Garam</p> 
                                    <span class="text-gray-500 text-xs">{{currentDevice.salinity}} m3/kg Kadar Garam pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                      <div id="salinity"></div>
                                </div>
                            </div>
                             <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Chloride</p> 
                                    <span class="text-gray-500 text-xs">{{currentDevice.chloride}} Chloride pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                      <div id="chloride"></div>
                                </div>
                            </div>

                             <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Nitrate</p> 
                                    <span class="text-gray-500 text-xs">{{currentDevice.nitrate}} Nitrate pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                      <div id="nitrate"></div>
                                </div>
                            </div>
                            <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">TSS</p> 
                                    <span class="text-gray-500 text-xs">{{currentDevice.TSS}} mg/L TSS pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                      <div id="TSS"></div>
                                </div>
                            </div>
                             <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">TDS</p> 
                                    <span class="text-gray-500 text-xs">{{currentDevice.TDS}} mg/L TDS pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                      <div id="TDS"></div>
                                </div>
                            </div>
                          
        
                             <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Sinyal GSM</p> 
                                    <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.CSQ).toFixed(0)}} sinyal GSM pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                    <div id="signal"></div>
                                </div>
                            </div>
                            <!-- <div class="col-span-12 md:col-span-6 rounded shadow p-4 border hover:border-blue-400">
                                <div id="level"></div>
                            </div> -->
                             <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Voltase Solar Panel</p> 
                                    <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.VSOL)}} V voltase solar panel pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                  <div id="vsol"></div>
                                </div>
                            </div>
                            <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Kelembapan</p> 
                                    <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.HUM).toFixed(0)}} % kelembapan pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                    <div id="humidity"></div>
                                </div>
                            </div>

                            <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Tekanan Atmosfir</p> 
                                    <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.PATM).toFixed(0)}} kPa tekanan atmosfer pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                   <div id="atmosfer"></div>
                                </div>
                            </div>

                            <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Baterai</p> 
                                    <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.VBAT).toFixed(2)}} VBAT baterai pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                           
                                <div class="p-4">
                                        <div id="batre"></div>
                                </div>
                            </div>
                           

                        </div>
                    </div>

                    <div id="form_lamaran" v-if="dialog" class="absolute"  style="z-index:110000 !important">
                        <div class="fixed inset-0 z-50 opacity-50 bg-black"></div>
                            <div class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50">
                                <div class="bg-white w-full md:w-3/4">
                                <div class="bg-gray-100 p-4 rounded rounded-b-none flex justify-between items-center">
                                    <p>Data Perangkat</p>
                                    <i class="far fa-times-square text-lg cursor-pointer" @click="closeDialog"></i>
                                </div>
                                <div class="bg-white w-full rounded rounded-t-none p-4 overflow-auto max-h-screen">
                                    <div style="w-full">
                                        <div class="w-full">
                                            <device-component class="w-full" v-on:close="closeDialog"/>
                                        </div>
                        
                                    
                                    </div>

                                </div>
                                </div>
                        </div>
                    </div>
            </div>

            <div id="form_lamaran" v-if="dialog_danger" class="absolute" style="z-index:110000 !important">
                <div class="fixed inset-0 z-50 opacity-50 bg-black"></div>
                    <div class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50">
                        <div class="bg-white p-4 w-full md:w-3/4 ">
                            <img src="/img/ph_air.png" class="w-full" alt="">
                            <div class=" btn border text-center border-gray-300 p-1 px-4 w-full font-semibold hover:bg-gray-100
                            cursor-pointer text-gray-500 rounded" id="closemenu" @click="stop()">
                            Tutup</div>
                        </div>
                    </div>
                </div>
            </div>
            <audio style="display: none;" id="backMusic">
                <source :src="require('../../assets/emergency.mp3')" type="audio/mpeg">
            </audio>
            
        </div>

</template>
<script>
var chartSignal,chartBatre,chartVsol,chartHumidity,chartAtmosfer,chartPh,chartConductivity
var chartTemperature,chartDO,chartTurbidity,chartAmonium,chartDensity,chartSalinity, chartChloride, chartNitrate, chartTSS,chartTDS
import { Carousel, Slide } from 'vue-carousel';
import { mapActions, mapGetters } from 'vuex';
import deviceComponent from '../../components/global/device.vue'
import mapComponent from '../../components/singleMap.vue'
import ApexCharts from 'apexcharts'
import moment from 'moment'
import optionPh from './optionPh'
import phComponent from './component/ph.vue'
import optionBatre from './optionBatre'
import optionConductivity from './optionConductivity'
import optionTemperature from './optionTemperature'
import optionTurbidity from './optionTurbidity'
import optionAmonium from './optionAmonium'
import optionDensity from './optionDensity'
import optionChloride from './optionChloride'
import optionNitrate from './optionNitrate'
import optionSignal from './optionSignal'
import optionVsol from './optionVsol'
import optionHumidity from './optionHumidity'
import optionTSS from './optionTSS'
import optionTDS from './optionTDS'
import optionDO from './optionDO'
import optionAtmosfer from './optionAtmosfer'
import optionSalinity from './optionSalinity'
import globalMixin from '../../mixin/global'
import textPh from '../../components/dashboard/textPh.vue'
export default {
    data () {
    return {
        hours: 0,
        minutes: 0,
        seconds: 0,
        dialog:false,
        loadingChart:false,
        update:'',
        dataPh:[],
        dataConductivity:[],
        dataTemperature:[],
        dataChart:[],
        batre:[],
        dataLevel:[],
        dataSignal:[],
        dataVsol:[],
        dataDO:[],
        dataTurbidity:[],
        dataAmonium:[],
        dataNitrate:[],
        dataBatre:[],
        dataHumidity:[],
        dataElevasi:[],
        dataAtmosfer:[],
        dataTSS:[],
        dataTDS:[],
        dataSalinity:[],
        dataDebit:[],
        dataChloride:[],
        mute: true,
        audio2: null,
        dialog_danger:false,
        first_danger:true
      }
    },
    components: {
        Carousel,
        Slide,
        textPh,
        deviceComponent,mapComponent,phComponent
       
    },
    mixins:[globalMixin, optionBatre,optionTemperature,optionSignal,
    optionConductivity,optionVsol,optionHumidity,optionAtmosfer,optionPh,optionNitrate,
    optionDO,optionTurbidity,optionAmonium,optionDensity,optionSalinity,optionChloride,optionTSS,optionTDS],
    methods: {
        ...mapActions({
           setCurrentDevice:'device/setCurrentDevice',
        }),
        async setDevice(item){
            this.first_danger = true
            await this.setCurrentDevice(item)
        },
        setTime () {
            setInterval(() => {
                const date = new Date()
                this.hours = date.getHours()
                this.minutes = this.checkSingleDigit(date.getMinutes())
                this.seconds = this.checkSingleDigit(date.getSeconds())
            }, 1000)
        },
        closeDialog() {
            this.dialog = false
        },
        checkSingleDigit (digit) {
            return ('0' + digit).slice(-2)
        },
        async getData() {
            await this.axios.get('v2/device/detail/data/' + this.currentDevice.id,this.config)
            .then((ress) => {
                console.log(ress)
                this.dataChart = ress.data
                  
            })
            .catch((err) => {
                this.errorResponse(err)
            })
        },
        async pushData(x){
             let label = x.last_update
                 await this.dataPh.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.pH).toFixed(4)
                })
                await this.dataDensity.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.density).toFixed(4)
                })
                await this.dataNitrate.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.nitrate).toFixed(4)
                })
                 await this.dataTSS.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.TSS).toFixed(4)
                })
                 await this.dataTDS.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.TDS).toFixed(4)
                })
                
                await this.dataConductivity.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.conductivity).toFixed(4)
                })
                 await this.dataDO.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.DO).toFixed(4)
                })
                await this.dataTemperature.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.temperature).toFixed(4)
                })
                await this.dataTurbidity.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.turbidity).toFixed(4)
                })
                 await this.dataAmonium.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.ammonium).toFixed(4)
                })
                await this.dataSalinity.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.salinity).toFixed(4)
                })
                await this.dataChloride.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.chloride).toFixed(4)
                })
                  await this.dataVsol.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.VSOL)
                })
                  await this.dataHumidity.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.HUM).toFixed(0)
                })
                 await this.dataAtmosfer.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.PATM).toFixed(2)
                })
                await this.dataSignal.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.CSQ).toFixed(2)
                })
                await this.batre.push({
                    x: new Date(label).getTime(),
                    y:3.85
                })
                
                await this.dataBatre.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.VBAT)
                })
              
        },
        async updateChart() {
            await this.$set(this.optionsPh,'series',[{name:'pH Air',data:this.dataPh}])
            await this.$set(this.optionsConductivity,'series',[{name:'Conductivity [ms/cm]',data:this.dataConductivity}])
            await this.$set(this.optionsTemperature,'series',[{name:'Temperature [deg C]',data:this.dataTemperature}])
            await this.$set(this.optionsTurbidity,'series',[{name:'Turbidity [NTU]',data:this.dataTurbidity}])
            await this.$set(this.optionsDO,'series',[{name:'Oksigen Terlarut [mg/L]',data:this.dataDO}])
            await this.$set(this.optionsSignal,'series',[{name:'Sig [-]',data:this.dataSignal}])
            await this.$set(this.optionsVsol,'series',[{name:'Voltase V',data:this.dataVsol}])
            await this.$set(this.optionsHumidity,'series',[{name:'Kelembapan',data:this.dataHumidity}])
            await this.$set(this.optionsAtmosfer,'series',[{name:'Tekanan Atmosfir kPa',data:this.dataAtmosfer}])
            await this.$set(this.optionsAmonium,'series',[{name:'Amonium',data:this.dataAmonium}])
            await this.$set(this.optionsDensity,'series',[{name:'Kepadatan Air [m3/kg]',data:this.dataDensity}])
            await this.$set(this.optionsSalinity,'series',[{name:'Kadar Garam [PSU]',data:this.dataSalinity}])
            await this.$set(this.optionsChloride,'series',[{name:'Chloride',data:this.dataChloride}])
            await this.$set(this.optionsNitrate,'series',[{name:'Nitrate',data:this.dataNitrate}])
            await this.$set(this.optionsTSS,'series',[{name:'TSS',data:this.dataTSS}])
            await this.$set(this.optionsTDS,'series',[{name:'TDS',data:this.dataTDS}])
          
            await this.$set(this.optionsBatre,'series',[
                {name:'VBAT',
                type: 'line',
                    data:this.dataBatre
                },
                {name:'Low Batery',
                    data:this.batre,
                    type:'area',

                }
            ])
            await this.$set(this.optionsBatre,'colors',[
                "#1899fb","#FF1654"
            ])

        },
        async levelChart(update = '') {
            if (!update) {
                // document.querySelector("#level").innerHTML = ''
                document.querySelector("#ph").innerHTML = ''
                document.querySelector("#signal").innerHTML = ''
                document.querySelector("#batre").innerHTML = ''
                document.querySelector("#vsol").innerHTML = ''
                document.querySelector("#humidity").innerHTML = ''
                document.querySelector("#atmosfer").innerHTML = ''
                document.querySelector("#conductivity").innerHTML = ''
                document.querySelector("#temperature").innerHTML = ''
                document.querySelector("#do").innerHTML = ''
                document.querySelector("#turbidity").innerHTML = ''
                document.querySelector("#amonium").innerHTML = ''
                document.querySelector("#density").innerHTML = ''
                document.querySelector("#salinity").innerHTML = ''
                document.querySelector("#chloride").innerHTML = ''
                document.querySelector("#nitrate").innerHTML = ''
                document.querySelector("#TSS").innerHTML = ''
                document.querySelector("#TDS").innerHTML = ''
                
            }
            this.batre = this.dataChart.batre
             this.dataBatre = this.dataChart.dataBatre
            this.dataPh = this.dataChart.dataPh
            this.dataConductivity = this.dataChart.dataConductivity
            this.dataTemperature = this.dataChart.dataTemperature
            this.dataSignal = this.dataChart.dataSignal
            this.dataVsol = this.dataChart.dataVsol
            this.dataHumidity = this.dataChart.dataHumidity
            this.dataAtmosfer = this.dataChart.dataAtmosfer
            this.dataAmonium = this.dataChart.dataAmonium
            this.dataDensity = this.dataChart.dataDensity
            this.dataDO = this.dataChart.dataDO
            this.dataTurbidity = this.dataChart.dataTurbidity
            this.dataSalinity = this.dataChart.dataSalinity
            this.dataChloride = this.dataChart.dataChloride
            this.dataNitrate = this.dataChart.dataNitrate
            this.dataTSS = this.dataChart.dataTSS
            this.dataTDS = this.dataChart.dataTDS
            await this.updateChart()
           
            
            if (update != '') {
               
              
            }else {
                chartBatre = new ApexCharts(document.querySelector("#batre"), this.optionsBatre);
                chartPh = new ApexCharts(document.querySelector("#ph"), this.optionsPh);
                chartSignal = new ApexCharts(document.querySelector("#signal"), this.optionsSignal);
                chartVsol = new ApexCharts(document.querySelector("#vsol"), this.optionsVsol);
                chartHumidity = new ApexCharts(document.querySelector("#humidity"), this.optionsHumidity);
                chartAtmosfer = new ApexCharts(document.querySelector("#atmosfer"), this.optionsAtmosfer);
                chartAmonium = new ApexCharts(document.querySelector("#amonium"), this.optionsAmonium);
                chartConductivity = new ApexCharts(document.querySelector("#conductivity"), this.optionsConductivity);
                chartTemperature = new ApexCharts(document.querySelector("#temperature"), this.optionsTemperature);
                chartTurbidity = new ApexCharts(document.querySelector("#turbidity"), this.optionsTurbidity);
                chartDensity = new ApexCharts(document.querySelector("#density"), this.optionsDensity);
                chartSalinity = new ApexCharts(document.querySelector("#salinity"), this.optionsSalinity);
                chartChloride = new ApexCharts(document.querySelector("#chloride"), this.optionsChloride);
                chartNitrate = new ApexCharts(document.querySelector("#nitrate"), this.optionsNitrate);
                chartDO = new ApexCharts(document.querySelector("#do"), this.optionsDO);
                chartTSS = new ApexCharts(document.querySelector("#TSS"), this.optionsTSS);
                chartTDS = new ApexCharts(document.querySelector("#TDS"), this.optionsTDS);

                await chartPh.render();
                await chartVsol.render();
                await chartHumidity.render();
                await chartSignal.render();
                await chartBatre.render();
                await chartAtmosfer.render();
                await chartConductivity.render();
                await chartDO.render();
                await chartTemperature.render();
                await chartTurbidity.render();
                await chartAmonium.render();
                await chartDensity.render();
                await chartSalinity.render();
                await chartChloride.render();
                await chartNitrate.render();
                await chartTSS.render();
                await chartTDS.render();

            }

            // let base = await chartSignal.dataURI();
            // console.log(base.imgURI)
            
           
        },

        async createdChart() {
            await this.getData()
            await this.levelChart()
        },

        play(){
            
            this.audio2.play();
            this.audio2.loop = true;
            this.mute = false;
           
        },
        stop() {
            this.dialog_danger = false
   
        }
    }, 
    computed:{
        ...mapGetters({
            device:'device/showDevice',
            currentDevice:'device/currentDevice'
        })
    },
    async created() {
        if (!this.currentDevice.name) {
            this.$router.push('/monitor')
        }
        await this.createdChart()
        // this.update = setInterval(() => {
        //         this.axios.get('v1/device_data/faker/' +this.currentDevice.id )
        //         .then(async ress => {
        //             let data = ress.data.data
        //             await this.getData()
        //             await this.levelChart(true)
        //             this.currentDevice.last_update = data.last_update
        //             this.currentDevice.CSQ = data.CSQ
        //             this.currentDevice.real_ketinggian = data.level
        //             this.currentDevice.VBAT = data.VBAT
                
        //         })
        //     }, 5000)
    },
    mounted () {
        this.setTime()
        this.audio2 = document.getElementById('backMusic');
        this.sockets.subscribe('updatedatachart', async (data) => {
            console.log(data.id + " " + this.currentDevice.id)
             if (data.id == this.currentDevice.id) {
                this.currentDevice.last_update = data.last_update
                this.currentDevice.CSQ = data.CSQ
                this.currentDevice.pH = data.pH
                this.currentDevice.VBAT = data.VBAT
                await this.pushData(data)
                 await chartPh.updateSeries([{data:this.dataPh}])
                await chartSignal.updateSeries([{data:this.dataSignal}])
                await chartVsol.updateSeries([{data:this.dataVsol}])
                 await chartHumidity.updateSeries([{data:this.dataHumidity}])
                 await chartAtmosfer.updateSeries([{data:this.dataAtmosfer}])
                 await chartConductivity.updateSeries([{data:this.dataConductivity}])
                 await chartTemperature.updateSeries([{data:this.dataTemperature}])
                 await chartTurbidity.updateSeries([{data:this.dataTurbidity}])
                 await chartDO.updateSeries([{data:this.dataDO}])
                 await chartAmonium.updateSeries([{data:this.dataAmonium}])
                 await chartDensity.updateSeries([{data:this.dataDensity}])
                 await chartSalinity.updateSeries([{data:this.chartSalinity}])
                 await chartChloride.updateSeries([{data:this.chartChloride}])
                 await chartNitrate.updateSeries([{data:this.chartNitrate}])
                 await chartTSS.updateSeries([{data:this.chartTSS}])
                 await chartTDS.updateSeries([{data:this.chartTDS}])
                
                 chartBatre.updateSeries([
                    {name:'VBAT',
                    type: 'line',
                        data:this.dataBatre
                    },
                    {name:'Low Batery',
                        data:this.batre,
                        type:'area',

                    }
                ])
             }
              
        });
        // this.play()
    },
    watch: {
    // whenever question changes, this function will run
        currentDevice: async function (newQuestion, oldQuestion) {
            await this.createdChart()
        }
    },
    beforeDestroy() {
        // clearInterval(this.update)
        this.sockets.unsubscribe('updatedatachart');

        clearInterval(this.setTime)
        this.audio2.pause();
        this.mute = true;
    }
 
}
</script>