<template>
    <div>
        <LevelComponent v-if="currentDevice.type == 'level'" />
        <WqComponent v-if="currentDevice.type == 'WQ'" />
        <RainComponent v-if="currentDevice.type == 'rain'" />
        <WeatherComponent  v-if="currentDevice.type == 'weather'" />
        <TimelapseComponent  v-if="currentDevice.type == 'camera'" />
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import LevelComponent from './level.vue'
import WqComponent from './waterquality.vue'
import RainComponent from './raingouge.vue'
import WeatherComponent from './weatherstation.vue'
import TimelapseComponent from './camera.vue'
export default {
    computed:{
          ...mapGetters({
            currentDevice:'device/currentDevice'
        })
    },
    created() {
        console.log("CURRENT DEVICE :", this.currentDevice)
    },
    watch: {
        currentDevice: function (newVal,oldVal) {
            if (this.currentDevice == '') {
                this.$router.push('/monitor')
            }
        },
    },
    components:{LevelComponent,WqComponent,RainComponent,WeatherComponent,TimelapseComponent}
}
</script>