<template>
    <div class="p-8 text-xs">
        <button @click="$router.push('/monitor')" class="flex items-center text-blue-500">
            <!-- Font Awesome for "chevron-left" -->
            <i class="fas fa-chevron-left w-6 h-6 mb-2"></i>
            <span class="ml-2 mb-5">Kembali</span>
        </button>
        <div class="">
            <div class="">
                       <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-4 rounded-2xl bg-white p-4 flex items-center">
                            <div class="p-4 rounded-full bg-blue-50 flex flex-wrap justify-center mr-4">
                                <unicon width="20px" height="20px" name="cloud-showers-heavy" fill="#3B82F6" ></unicon>

                            </div>
                            <div class="text-lg font-bold"> 
                                {{parseFloat(currentDevice.precipitation).toFixed(2)}} mm 
                                (<span  v-if="currentDevice.precipitation >= 0.2 && currentDevice.precipitation < 0.4 ">Rendah</span>
                                    <span  v-else-if="currentDevice.precipitation < 0.19">Sangat Rendah</span>
                                    <span  v-else-if="currentDevice.precipitation >= 0.4 && currentDevice.precipitation < 0.6 ">Sedang</span>
                                    <span  v-else-if="currentDevice.precipitation >= 0.6 && currentDevice.precipitation < 0.8 ">Kuat</span>
                                    <span  v-else-if="currentDevice.precipitation >= 0.8 ">Sangat Kuat</span>)

                                <p class="text-gray-500 text-xs font-normal">Curah hujan</p>
                            </div>
                        </div>
                        <div class="col-span-4 rounded-2xl bg-white p-4 flex items-center">
                            <div class="flex flex-wrap justify-center items-center rounded-full bg-green-50 px-4 py-5 mr-4" v-if="currentDevice.persentase_battery >= 80" >
                                <img src="/img/100.svg" alt=""> 
                            </div>
                            <div class="flex flex-wrap justify-center items-center rounded-full bg-green-50 px-4 py-5 mr-4" v-else-if="currentDevice.persentase_battery < 80 && currentDevice.persentase_battery > 50">
                                 <img src="/img/75.svg" alt=""> 
                            </div>
                            <div class="flex flex-wrap justify-center items-center rounded-full bg-yellow-50 px-4 py-5 mr-4" v-else-if="currentDevice.persentase_battery <= 50 && currentDevice.persentase_battery >= 40">
                                 <img src="/img/50.svg" alt=""> 

                            </div>
                            <div class="flex flex-wrap justify-center items-center rounded-full bg-red-50 px-4 py-5 mr-4" v-else-if="currentDevice.persentase_battery < 40 && currentDevice.persentase_battery > 0">
                                <img src="/img/25.svg" alt=""> 

                            </div>
                            <div class="flex flex-wrap justify-center items-center rounded-full bg-red-50 px-4 py-5 mr-4" v-else-if="currentDevice.persentase_battery <= 0 " >
                                <img src="/img/0.svg" alt=""> 

                            </div>
                            <div>
                                <p class="text-lg font-bold">{{currentDevice.persentase_battery}}%</p>
                                <p class="text-gray-500">Baterai</p>
                            </div>
                        </div>
                        <div class="col-span-4 rounded-2xl bg-white p-4 flex items-center">
                                <div class="p-4 rounded-full bg-blue-50 flex flex-wrap justify-center mr-4">
                                <unicon width="20px" height="20px" name="brightness" fill="#3B82F6" ></unicon>

                                </div>
                                <div>
                                    <p class="text-lg font-bold">{{currentDevice.VSOL}} V</p>
                                    <p class="text-gray-500">Voltase Solar Panel</p>
                                </div>
                        </div>
                        </div>
                    <div class="mt-4 mb-2">
                       <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12  md:col-span-8 rounded bg-white z-30 md:h-full">
                                <map-component class="rounded-2xl" :device="device" v-if="device.length"/>
                            </div>
                            <div class="col-span-12 md:col-span-4 rounded-2xl p-6 bg-white">
                                <div class="">
                                    <span class="text-lg font-bold">{{currentDevice.name}}</span>
                                    <br>
                                    <span class="text-gray-500">{{currentDevice.code}}</span>
                                </div>
                                <div @click="dialog = true" class="mt-4 cursor-pointer rounded-lg text-sm font-semibold text-center py-2 text-white bg-blue-500">
                                    Ganti Perangkat
                                </div>

                                <hr class="mt-4">

                                <div class="flex items-center text-sm mt-4">
                                    <unicon width="20px" height="20px" name="location-point" fill="#9CA3AF" class="mr-2"></unicon>
                                    <a class="text-gray-500" :href="`https://www.google.com/maps/place/${currentDevice.latitude} ${currentDevice.longitude}`" target="_blank" rel="noopener noreferrer">
                                    {{currentDevice.latitude}} {{currentDevice.longitude}}
                                    </a>
                                </div>
                                <hr class="mt-4">

                                <div class="flex items-center text-sm mt-4 text-gray-500">
                                    <unicon width="20px" height="20px" name="calendar-alt" fill="#9CA3AF" class="mr-2"></unicon>
                                    {{parseDate(currentDevice.last_update,'YYYY-MM-DD HH:mm:ss')}}
                                </div>
                                <hr class="mt-4">
                                <div class="flex items-center text-sm mt-4 text-gray-500">
                                    <unicon width="20px" height="20px" name="sim-card" fill="#9CA3AF" class="mr-2"></unicon>
                                   {{currentDevice.gsm?currentDevice.gsm.name : ''}}
                                </div>
                                <hr class="mt-4">
                                  <div class="flex items-center text-sm mt-4 text-gray-500">
                                    <unicon width="20px" height="20px" name="signal" fill="#9CA3AF" class="mr-2"></unicon>
                                   {{parseFloat(currentDevice.CSQ).toFixed(0)}} Sig[-]
                                  
                                </div>
                                <hr class="mt-4">
                                  <div class="flex items-center text-sm mt-4 text-gray-500">
                                    <unicon width="20px" height="20px" name="circuit" fill="#9CA3AF" class="mr-2"></unicon>
                                    {{currentDevice.tipe_sensor}}
                                </div>

                            </div>
                            <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Rainfall intensity</p> 
                                    <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.precipitation).toFixed(2)}} mm rainfall intensity pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                    <div id="ph"></div>
                                </div>
                            </div>
                            
                            
                            <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Sinyal GSM</p> 
                                    <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.CSQ).toFixed(0)}} sinyal GSM pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                    <div id="signal"></div>
                                </div>
                            </div>
                      
                             <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Voltase Solar Panel</p> 
                                    <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.VSOL)}} V voltase solar panel pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                  <div id="vsol"></div>
                                </div>
                            </div>
                            <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Kelembapan</p> 
                                    <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.HUM).toFixed(0)}} % kelembapan pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                    <div id="humidity"></div>
                                </div>
                            </div>

                            <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Tekanan Atmosfir</p> 
                                    <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.PATM).toFixed(0)}} kPa tekanan atmosfer pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                                <div class="p-4">
                                   <div id="atmosfer"></div>
                                </div>
                            </div>

                            <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                                <div class="pt-4 px-8">
                                    <p class="font-bold text-base">Baterai</p> 
                                    <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.VBAT).toFixed(2)}} VBAT baterai pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span>
                                </div>
                           
                                <div class="p-4">
                                        <div id="batre"></div>
                                </div>
                            </div>

                           

                        </div>
                    </div>

                <div id="form_lamaran" v-if="dialog" class="absolute"  style="z-index:110000 !important">
                    <div class="fixed inset-0 z-50 opacity-50 bg-black"></div>
                        <div class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50">
                            <div class="bg-white w-full md:w-3/4">
                            <div class="bg-gray-100 p-4 rounded rounded-b-none flex justify-between items-center">
                                <p>Data Perangkat</p>
                                <i class="far fa-times-square text-lg cursor-pointer" @click="closeDialog"></i>
                            </div>
                            <div class="bg-white w-full rounded rounded-t-none p-4 overflow-auto max-h-screen">
                                <div style="w-full">
                                    <div class="w-full">
                                        <device-component class="w-full" v-on:close="closeDialog"/>
                                    </div>
                    
                                
                                </div>

                            </div>
                            </div>
                        </div>
                </div>
            </div>

          
            </div>
            <audio id="backMusic">
                <source :src="require('../../assets/emergency.mp3')" type="audio/mpeg">
            </audio>
            
        </div>

</template>
<script>
var chartSignal,chartBatre,chartVsol,chartHumidity,chartAtmosfer,chartPh
import { Carousel, Slide } from 'vue-carousel';
import { mapActions, mapGetters } from 'vuex';
import deviceComponent from '../../components/global/device.vue'
import mapComponent from '../../components/singleMap.vue'
import ApexCharts from 'apexcharts'
import moment from 'moment'
import optionPh from './optionRainSensor/optionRainfall'
import phComponent from './component/ph.vue'
import optionBatre from './optionBatre'
import optionAmonium from './optionAmonium'
import optionSignal from './optionSignal'
import optionTemperature from './optionTemperature'

import optionVsol from './optionVsol'
import optionHumidity from './optionHumidity'
import optionAtmosfer from './optionAtmosfer'
import globalMixin from '../../mixin/global'
export default {
    data () {
    return {
        hours: 0,
        minutes: 0,
        seconds: 0,
        dialog:false,
        loadingChart:false,
        update:'',
        dataPh:[],
        dataTemperature:[],
        dataChart:[],
        batre:[],
        dataLevel:[],
        dataSignal:[],
        dataVsol:[],
        dataDO:[],
        dataBatre:[],
        dataHumidity:[],
        dataElevasi:[],
        dataAtmosfer:[],
        mute: true,
        audio2: null,
        dialog_danger:false,
        first_danger:true
      }
    },
    components: {
        Carousel,
        Slide,
        deviceComponent,mapComponent,phComponent
       
    },
    mixins:[globalMixin, optionBatre,optionTemperature,optionSignal,
    optionVsol,optionHumidity,optionAtmosfer,optionPh],
    methods: {
        ...mapActions({
           setCurrentDevice:'device/setCurrentDevice',
        }),
        async setDevice(item){
            this.first_danger = true
            await this.setCurrentDevice(item)
        },
        setTime () {
            setInterval(() => {
                const date = new Date()
                this.hours = date.getHours()
                this.minutes = this.checkSingleDigit(date.getMinutes())
                this.seconds = this.checkSingleDigit(date.getSeconds())
            }, 1000)
        },
        closeDialog() {
            this.dialog = false
        },
        checkSingleDigit (digit) {
            return ('0' + digit).slice(-2)
        },
     
        async getData() {
            await this.axios.get('v2/device/detail/data/' + this.currentDevice.id,this.config)
            .then((ress) => {
                console.log(ress)
                this.dataChart = ress.data
                  
            })
            .catch((err) => {
                this.errorResponse(err)
            })
        },
        async pushData(x){
             let label = x.last_update
                 await this.dataPh.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.precipitation).toFixed(4)
                })
              
                  await this.dataVsol.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.VSOL)
                })
                  await this.dataHumidity.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.HUM).toFixed(0)
                })
                 await this.dataAtmosfer.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.PATM).toFixed(2)
                })
                await this.dataSignal.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.CSQ).toFixed(2)
                })
                await this.batre.push({
                    x: new Date(label).getTime(),
                    y:3.85
                })
                
                await this.dataBatre.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.VBAT)
                })
              
        },
        async updateChart() {
            await this.$set(this.optionsPh,'series',[{name:'Rainfall Intensity',data:this.dataPh}])
            await this.$set(this.optionsSignal,'series',[{name:'Sig [-]',data:this.dataSignal}])
            await this.$set(this.optionsVsol,'series',[{name:'Voltase V',data:this.dataVsol}])
            await this.$set(this.optionsHumidity,'series',[{name:'Kelembapan',data:this.dataHumidity}])
            await this.$set(this.optionsAtmosfer,'series',[{name:'Tekanan Atmosfir kPa',data:this.dataAtmosfer}])
            await this.$set(this.optionsBatre,'series',[
                {name:'VBAT',
                type: 'line',
                    data:this.dataBatre
                },
                {name:'Low Batery',
                    data:this.batre,
                    type:'area',

                }
            ])
            await this.$set(this.optionsBatre,'colors',[
                "#1899fb","#FF1654"
            ])

        },
        async levelChart(update = '') {
            if (!update) {
                // document.querySelector("#level").innerHTML = ''
                document.querySelector("#ph").innerHTML = ''
                document.querySelector("#signal").innerHTML = ''
                document.querySelector("#batre").innerHTML = ''
                document.querySelector("#vsol").innerHTML = ''
                document.querySelector("#humidity").innerHTML = ''
                document.querySelector("#atmosfer").innerHTML = ''
                
            }
            this.batre = this.dataChart.batre
             this.dataBatre = this.dataChart.dataBatre
            this.dataPh = this.dataChart.precipitation
            
            this.dataSignal = this.dataChart.dataSignal
            this.dataVsol = this.dataChart.dataVsol
            this.dataHumidity = this.dataChart.dataHumidity
            this.dataAtmosfer = this.dataChart.dataAtmosfer
            await this.updateChart()
           
            
            if (update != '') {
               
              
            }else {
                chartBatre = new ApexCharts(document.querySelector("#batre"), this.optionsBatre);
                chartPh = new ApexCharts(document.querySelector("#ph"), this.optionsPh);
                chartSignal = new ApexCharts(document.querySelector("#signal"), this.optionsSignal);
                chartVsol = new ApexCharts(document.querySelector("#vsol"), this.optionsVsol);
                chartHumidity = new ApexCharts(document.querySelector("#humidity"), this.optionsHumidity);
                chartAtmosfer = new ApexCharts(document.querySelector("#atmosfer"), this.optionsAtmosfer);
                

                await chartPh.render();
                await chartVsol.render();
                await chartHumidity.render();
                await chartSignal.render();
                await chartBatre.render();
                await chartAtmosfer.render();

            }

            // let base = await chartSignal.dataURI();
            // console.log(base.imgURI)
            
           
        },

        async createdChart() {
            await this.getData()
            await this.levelChart()
        },

        play(){
            
            this.audio2.play();
            this.audio2.loop = true;
            this.mute = false;
           
        },
        stop() {
            this.dialog_danger = false
   
        }
    }, 
    computed:{
        ...mapGetters({
            device:'device/showDevice',
            currentDevice:'device/currentDevice'
        })
    },
    async created() {
        if (!this.currentDevice.name) {
            this.$router.push('/monitor')
        }
        await this.createdChart()
        // this.update = setInterval(() => {
        //         this.axios.get('v1/device_data/faker/' +this.currentDevice.id )
        //         .then(async ress => {
        //             let data = ress.data.data
        //             await this.getData()
        //             await this.levelChart(true)
        //             this.currentDevice.last_update = data.last_update
        //             this.currentDevice.CSQ = data.CSQ
        //             this.currentDevice.real_ketinggian = data.level
        //             this.currentDevice.VBAT = data.VBAT
                
        //         })
        //     }, 5000)
    },
    mounted () {
        this.setTime()
        this.audio2 = document.getElementById('backMusic');
        this.sockets.subscribe('updatedatachart', async (data) => {
            console.log(data.id + " " + this.currentDevice.id)
             if (data.id === this.currentDevice.id) {
                this.currentDevice.last_update = data.last_update
                this.currentDevice.CSQ = data.CSQ
                this.currentDevice.precipitation = data.precipitation
                this.currentDevice.VBAT = data.VBAT
                await this.pushData(data)
                 await chartPh.updateSeries([{data:this.dataPh}])
                await chartSignal.updateSeries([{data:this.dataSignal}])
                await chartVsol.updateSeries([{data:this.dataVsol}])
                 await chartHumidity.updateSeries([{data:this.dataHumidity}])
                 await chartAtmosfer.updateSeries([{data:this.dataAtmosfer}])
               
                
                 chartBatre.updateSeries([
                    {name:'VBAT',
                    type: 'line',
                        data:this.dataBatre
                    },
                    {name:'Low Batery',
                        data:this.batre,
                        type:'area',

                    }
                ])
             }
              
        });
        // this.play()
    },
    watch: {
    // whenever question changes, this function will run
        currentDevice: async function (newQuestion, oldQuestion) {
            await this.createdChart()
        }
    },
    beforeDestroy() {
        // clearInterval(this.update)
        this.sockets.unsubscribe('updatedatachart');

        clearInterval(this.setTime)
        this.audio2.pause();
        this.mute = true;
    }
 
}
</script>